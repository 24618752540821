import React, { useContext, useEffect, useState } from 'react'

import { IconButton, Link, Typography, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import MUIDataTable from 'mui-datatables'
import { useHistory } from 'react-router-dom'

import FiltersItineraries from './FiltersItineraries'

import BackDrop from 'components/BackDrop'
import Button from 'components/CustomButtons/Button'
import ModalContainer from 'components/ModalContainer/ModalContainer'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { ADMIN_SISTECREDITO } from 'constants/rolesConst'
import { routesLink } from 'constants/routesConsts'
import useAuthContext from 'contextApi/AuthContext'
import { ItinerariesContext } from 'contextApi/ItineraryContext'
import useServiceTravel from 'services/travel/travel.service'

const Itineraries = () => {
  const showDialogAlert = useCustomDialogAlert()
  const history = useHistory()
  const { loadFilters, filters, loadStateItineraries } = useContext(ItinerariesContext)
  const [activeFilter, setActiveFilter] = useState('pendingReview')
  const [dataTable, setDataTable] = useState([])
  const [dataTableSearch, setDataTableSearch] = useState([])
  const [loader, setLoader] = useState(false)
  const [controlModal, setControlModal] = useState({ modal: false, state: '', idItinerary: '' })
  const findPaymentType = (paymentData) => {
    if (paymentData.isPaymentShared) {
      return `${paymentData.paymentsResponses[0].paymentType.name}, ${paymentData.paymentsResponses[1].paymentType.name}`
    }
    return paymentData.paymentType.name
  }
  const findStateItinerary = (getData, it) => {
    const getStatus = getData.filters.find((f) => {
      return f.name === it.orderStatus
    })
    if (getStatus) {
      return getStatus.description.replace('os', 'o')
    }
    return ''
  }
  const getDatePayment = (date) => {
    if (date.isPaymentShared) {
      return `${date.paymentsResponses[0].dateCreated.replace('/', '-').replace('/', '-')}`
    }
    return date.dateCreated.replace('/', '-').replace('/', '-')
  }

  const getStateNetactica = (state) => {
    if (state === 'true') {
      return 'Confirmado'
    }
    if (state === 'false') {
      return 'Pendiente'
    }
    if (state === 'fail') {
      return 'Fallido'
    }
    return ''
  }
  const buildDataTable = (it, getData) => {
    const date = new Date(it.createdAt)
    return {
      itineraryid: `${it.travelData.Id}`,
      date: `${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date
        .getUTCDate()
        .toString()
        .padStart(2, '0')}-${date.getUTCFullYear()}  ${date.getUTCHours()}:${date
        .getUTCMinutes()
        .toString()
        .padStart(2, '0')}`,
      datepayment: getDatePayment(it.paymentData),
      paymentmethod: findPaymentType(it.paymentData),
      price: it.paymentData.isPaymentShared
        ? `${it.paymentData.transactionTotalValue}`
        : it.paymentData.valueTransaction,
      state: it.orderStatus,
      description: findStateItinerary(getData, it),
      statusNetactica: getStateNetactica(it.orderStatusConfirmedNectactica),
    }
  }
  const loadItineraries = (filter) => {
    setActiveFilter(filter)
    useServiceTravel.getItineraries(filter).then((i) => {
      const getData = i.data.data
      loadFilters(getData.filters)
      loadStateItineraries(getData.itineraries)
      const getItinerariesDatails = []
      getData.itineraries.forEach((it) => {
        if (!it.paymentData.isPaymentShared && it.paymentData.paymentType.id === 1) {
          getItinerariesDatails.push(buildDataTable(it, getData))
        } else if (it.paymentData.isPaymentShared) {
          const findSiste = it.paymentData.paymentsResponses.find((p) => {
            return p.paymentType.id === 1 || p.paymentType.id === 7
          })
          if (findSiste) {
            getItinerariesDatails.push(buildDataTable(it, getData))
          }
        }
      })
      setDataTableSearch(getItinerariesDatails)
      setDataTable(getItinerariesDatails)
      setLoader(false)
    })
  }

  const setNewItineraryStatus = (id, newState) => {
    setLoader(true)
    useServiceTravel
      .itineraryChangeStatus(id, newState)
      .then(() => {
        setLoader(false)
        loadItineraries(activeFilter)
        showDialogAlert(true, 'Cambio realizado exitosamente', null, 'Cerrar', '', true)
      })
      .catch(() => {
        setLoader(false)
        showDialogAlert(
          true,
          'El cambio no pudo realizarse, inténtalo nuevamente más tarde',
          null,
          'Cerrar',
          '',
          false
        )
      })
  }

  useEffect(() => {
    setLoader(true)
    useServiceTravel.getJwt().then((t) => {
      const { token } = t.data.data
      localStorage.setItem('luegopago_travel_data_token', token)
      loadItineraries(activeFilter)
    })
  }, []) // eslint-disable-line
  const ThousandsPipe = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  const { userLoggedin } = useContext(useAuthContext)
  const options = {
    print: false,
    search: false,
    filter: false,
    sort: false,
    pagination: true,
    viewColumns: false,
    download: false,
    jumpToPage: false,
    filterType: 'multiselect',
    fixedSelectColumn: false,
    responsive: 'scrollMaxHeight',
    selectableRows: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 20, 50],
    textLabels: {
      body: {
        noMatch: 'No hay registros',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
      },
      selectedRows: {
        text: 'Fila(s) Seleccionada(s)',
      },
    },
  }
  const columns = [
    {
      name: 'itineraryid',
      label: 'NÚMERO DE ITINERARIO',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
              NÚMERO DE ITINERARIO
            </th>
          )
        },
        customBodyRender: (value) => {
          return (
            <div>
              <Link
                href={`${routesLink.itineraryDetails}/${value}`}
                color="secondary"
                onClick={(event) => {
                  event.preventDefault()
                  history.push(`${routesLink.itineraryDetails}/${value}`)
                }}
              >
                <Typography variant="body1" style={{ marginLeft: '5%' }}>
                  {value}
                </Typography>
              </Link>
            </div>
          )
        },
      },
    },
    {
      name: 'date',
      label: 'FECHA DE CREACIÓN',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>FECHA DE CREACIÓN</th>
          )
        },
        customBodyRender: (value) => {
          return <div style={{ fontFamily: 'Red Hat Display Regular' }}>{value}</div>
        },
      },
    },
    {
      name: 'datepayment',
      label: 'FECHA DE PAGO',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>FECHA DE PAGO</th>
          )
        },
        customBodyRender: (value) => {
          return <div style={{ fontFamily: 'Red Hat Display Regular' }}>{value}</div>
        },
      },
    },
    {
      name: 'paymentmethod',
      label: 'MÉTODO DE PAGO',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>MÉTODO DE PAGO</th>
          )
        },
        customBodyRender: (value) => {
          return <div style={{ fontFamily: 'Red Hat Display Regular' }}>{value}</div>
        },
      },
    },
    {
      name: 'price',
      label: 'PRECIO',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>PRECIO</th>
        },
        customBodyRender: (value) => {
          return (
            <div style={{ fontFamily: 'Red Hat Display Regular' }}>
              {`$ ${value ? ThousandsPipe(value) : ''}`}
            </div>
          )
        },
      },
    },
    {
      name: 'description',
      label: 'ESTADO',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>ESTADO</th>
        },
        customBodyRender: (value) => {
          return <Typography variant="body1">{value}</Typography>
        },
      },
    },
    {
      name: 'statusNetactica',
      label: 'Estado en Netactica',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>
              Estado en Netactica
            </th>
          )
        },
        customBodyRender: (value) => {
          return <Typography variant="body1">{value}</Typography>
        },
      },
    },
    {
      name: 'changeState',
      label: ' ',
      options: {
        dispay: false,
        customBodyRender: (value, tableMeta) => {
          let idItinerary = ''
          if (tableMeta.rowData) {
            const { rowData } = tableMeta
            const [id] = rowData
            idItinerary = id
          }
          if (userLoggedin.role === ADMIN_SISTECREDITO && activeFilter === 'pendingReview') {
            return (
              <>
                <Tooltip title="Aprobar">
                  <IconButton
                    onClick={(event) => {
                      setControlModal({ modal: true, state: 'approved', idItinerary: idItinerary })
                      event.preventDefault()
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Rechazar">
                  <IconButton
                    onClick={(event) => {
                      setControlModal({ modal: true, state: 'cancel', idItinerary: idItinerary })
                      event.preventDefault()
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
        },
      },
    },
  ]
  return (
    <div className="lupa_seller_view_itinerary_container">
      <BackDrop open={loader} />
      <ModalContainer
        xl={8}
        md={6}
        sm={8}
        open={controlModal.modal}
        title="Cambio de estado de itinerario"
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}
        >
          <Typography variant="subtitle1">¿Esta seguro de guardar el cambio realizado?</Typography>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Button
              color="error"
              onClick={() => {
                setControlModal({ modal: false, state: '', idItinerary: '' })
              }}
            >
              Cancelar
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                const { idItinerary, state } = controlModal
                setNewItineraryStatus(idItinerary, state)
                setControlModal({ modal: false, state: '', idItinerary: '' })
              }}
            >
              Guardar
            </Button>
          </div>
        </div>
      </ModalContainer>
      <div>
        <Typography variant="h6" style={{ color: '#6064FF' }}>
          Listado de itinerarios
        </Typography>
      </div>
      <div>
        <FiltersItineraries
          dispatchValue={(v) => {
            if (v === 'clear') {
              setDataTableSearch(dataTable)
            } else {
              const newDataSearch = dataTableSearch.filter((i) => {
                return i.itineraryid.includes(v)
              })
              setDataTableSearch(newDataSearch)
            }
          }}
          runFilter={(filter) => {
            setLoader(true)
            loadItineraries(filter)
          }}
          activeFilter={activeFilter}
          filters={filters}
        />
      </div>
      <div>
        <MUIDataTable columns={columns} options={options} data={dataTableSearch} />
      </div>
    </div>
  )
}

export default Itineraries
